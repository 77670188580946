<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_plugin_store") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ row[td] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";

const FIELDS_DISPLAY = ["id", "name", "description", "author", "version"];

export default {
  data() {
    return {
      FIELDS_DISPLAY,
      rows: [],
    };
  },
  methods: {
    getPluginStore() {
      api
        .request({
          name: "getPluginStore",
        })
        .then(({ data }) => (this.rows = data.data));
    },
  },
  created() {
    this.getPluginStore();
  },
};
</script>

<style lang="scss" scoped></style>
